import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CloseSVG, ExternalSVG } from "../custom/svg";
import { GradientAnimation } from "../custom/animations";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none; /* Prevents touch scrolling on touch devices */
  z-index: 99; // prevents items showing in front
  cursor: pointer;
`;

const PopupContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  max-width: 500px;
  width: 50%;
  text-align: center;
  position: fixed; /* Changed from relative to fixed */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position to center */
  z-index: 1000;
  cursor: auto;

  @media (max-width: 760px) {
    top: 50%; /* Reset top positioning */
    // bottom: 1.5%; /* Position at the bottom */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust position to center */
    width: 85%; /* Set width to 85% on mobile */
    max-width: none; /* Remove max-width constraint */
    border-radius: 15px; /* Rounded top corners */
    max-height: 50vh; /* Limit height to 50% of viewport */
    overflow-y: hidden; /* Enable scrolling if content overflows */
    padding: 20px; /* Equal padding on all sides */
  }
`;

const CloseButton = styled.button`
  all: unset;
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border-radius: 5px;
  border: none;
  padding-top: 3px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    cursor: pointer;
  }
`;
const PopUpIngredientDiv = styled.div`
  display: flex;
  // padding-left: 15px;
  padding: 10px;
  padding-top: 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
const PopUpTitle = styled.p`
  display: block;
  height: auto;
  font-family: San Francisco Bold;
  font-size: 1.2rem;
  position: static;
  text-align: left;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
  }
`;
const PopUpDefinition = styled.p`
  font-size: 14px;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-bottom: 10px;
  margin: 0px;
`;
const ScrollableContent = styled.div`
  max-height: 60vh; /* Restrict the height of the scrollable content */
  overflow-y: auto; /* Makes the content inside this div scrollable */

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
const ProductCompareDiv = styled.div`
  width: 95%;
  position: static;
  //   padding-top: 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;

  @media (min-width: 760px) {
    flex-direction: column;
    width: 96.5%;
  }
`;
const ProductCompareBackground = styled(Link)`
  display: block;
  text-decoration: none;
  color: black;
  width: 100%;
  height: 80px;
  border-radius: 15px;
  //   padding-top: 5px;
  position: static;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background: linear-gradient(
    135deg,
    #efefef 0%,
    #efefef 45%,
    white 50%,
    #efefef 55%,
    #efefef 100%
  );
  background-size: 400% 400%;
  //   animation: ${GradientAnimation} 10s ease infinite;
  cursor: pointer;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 10px gray;
    }
  }
`;
const ProductCompareFront = styled.div`
  height: 70px;
  background-color: white;
  border-radius: 10px;
  position: static;
  margin: 5px;
`;
const ProductCompareOuterDiv = styled.div`
  display: flex;
  flex-direction: row;
  //   background: gray;
`;
const ProductCompareTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70px;
  width: 67%;
  //   margin-left: 0px;
  //   background: yellow;
`;
const CartDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ProductCompareBrand = styled.p`
  font-family: San Francisco Bold;
  font-size: 1rem;
  padding-left: 10px;
  line-height: 1.3rem;
  text-align: left;
  margin: 0px 0px;
  margin-bottom: 3px;
  line-height: 1.3rem;
`;
const ProductCompareFlavor = styled.p`
  font-family: San Francisco Regular;
  font-size: 0.9rem;
  text-align: left;
  color: gray;
  padding-left: 10px;
  margin: 0px 0px;
`;
const ProductImgDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  height: 70px;
  // background: yellow;
`;
const TestProductImgDiv = styled.div`
  display: flex;
  justify-content: center;
  // align-items: center;
  margin: 10px auto;
  width: 60px;
  //   background: yellow;
`;
const ProductImg = styled.img`
  display: block;
  height: 60px;
`;

const ProductCompareHeader = ({ item }) => {
  return (
    <ProductCompareBackground
      to={item.shoplink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ProductCompareFront>
        <ProductCompareOuterDiv>
          <ProductImgDiv>
            <TestProductImgDiv>
              <ProductImg src={item.imglink} />
            </TestProductImgDiv>
          </ProductImgDiv>
          <ProductCompareTitleDiv>
            <div>
              <ProductCompareBrand>{item.brand}</ProductCompareBrand>
              <ProductCompareFlavor>{item.subtitle}</ProductCompareFlavor>
            </div>
            {/* <ProductCompareVS>vs</ProductCompareVS>
            <div style={{ marginBottom: "13px" }}>
              <ProductCompareBrand>
                {itemTwo ? itemTwo.brand : "Product Two"}
              </ProductCompareBrand>
              <ProductCompareFlavor>
                {itemTwo ? compareSubmaker(itemTwo) : "Select from search."}
              </ProductCompareFlavor>
            </div> */}
          </ProductCompareTitleDiv>
          {item && (
            <CartDiv>
              <ExternalSVG color={"black"} />
            </CartDiv>
          )}
        </ProductCompareOuterDiv>
      </ProductCompareFront>
    </ProductCompareBackground>
  );
};

const categories = [
  {
    category: "shaker",
    title: "Need a Shaker?",
    subtitle:
      "Help support the development of SUPPHEAD by purchasing one below!",
  },
  {
    category: "clothes",
    title: "Need Gym Gear?",
    subtitle: "Help support the development of SUPPHEAD by purchasing below!",
  },
  {
    category: "wipes",
    title: "Too much Protein?",
    subtitle: "Help support the development of SUPPHEAD by purchasing below!",
  },
];

const AdPopUp = ({ canOpen, delay = 10000, accessories }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isTimerActive, setIsTimerActive] = useState(false); // set to false for prod when using timer

  useEffect(() => {
    const randomIndex = Math.floor(
      Math.random() *
        (window.location.pathname.includes("/proteinpowder") ||
        window.location.pathname.includes("/proteinbar") ||
        window.location.pathname.includes("/massgainer")
          ? 3
          : 2)
    ); // Choose 0 or 1
    setSelectedCategory(categories[randomIndex]);
  }, []);

  // TODO: NEED TO UPDATE LOGIC FOR isOpen (it never is called from parent page, this child component does all the work it just needs to check if it can open)

  useEffect(() => {
    let timer;

    if (canOpen) {
      // Get the last shown date from localStorage
      const lastShownDate = localStorage.getItem("bottle-popupLastShown");
      const now = new Date();

      // Check if the popup was shown more than an hour ago
      if (
        !lastShownDate ||
        now - new Date(lastShownDate) > 60 * 60 * 1000 // 1 hour
      ) {
        // Set a timer to open the popup after the specified delay
        timer = setTimeout(() => {
          setIsTimerActive(true);
          localStorage.setItem("bottle-popupLastShown", now.toISOString()); // Update the shown date
        }, delay);
      }
    }

    // Cleanup timer when component unmounts
    return () => clearTimeout(timer);
  }, [delay, canOpen]);

  useEffect(() => {
    if (canOpen && isTimerActive) {
      document.body.style.overflow = "hidden";
      const themeColorMetaTag = document.querySelector(
        'meta[name="theme-color"]'
      );
      if (themeColorMetaTag)
        themeColorMetaTag.setAttribute("content", "#7F7F7F");
    } else {
      document.body.style.overflow = "";
      const themeColorMetaTag = document.querySelector(
        'meta[name="theme-color"]'
      );
      if (themeColorMetaTag) themeColorMetaTag.setAttribute("content", "white");
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [canOpen, isTimerActive]);

  if (!canOpen || !isTimerActive) return null;

  const handleOverlayClick = (event) => {
    setIsTimerActive(false);
    // onClose(); // Close the popup if clicked outside
  };

  const handlePopupClick = (event) => {
    event.stopPropagation(); // Prevent the click from reaching the overlay
  };

  return (
    accessories &&
    selectedCategory && (
      <Overlay onClick={handleOverlayClick}>
        <PopupContainer onClick={handlePopupClick}>
          <CloseButton
            onClick={() => {
              setIsTimerActive(false);
            }}
          >
            <CloseSVG color={"black"} />
          </CloseButton>
          <PopUpIngredientDiv>
            <PopUpTitle>{selectedCategory.title}</PopUpTitle>
            <PopUpDefinition style={{ paddingBottom: "10px" }}>
              {selectedCategory.subtitle}
            </PopUpDefinition>
          </PopUpIngredientDiv>
          <ScrollableContent>
            <ProductCompareDiv>
              {/* need an outer div with rounded product cards */}
              {accessories &&
                accessories
                  .filter((item) => item.category === selectedCategory.category)
                  .map((item, index) => {
                    return <ProductCompareHeader item={item} />;
                  })}
            </ProductCompareDiv>
          </ScrollableContent>
        </PopupContainer>
      </Overlay>
    )
  );
};

export default AdPopUp;
