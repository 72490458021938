import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Fonts from "./fonts/Fonts";
import Loading from "./components/Loading";
import Error from "./pages/Error";
import styled from "styled-components";
import Home from "./pages/Home";
import { pageview, initializeGA } from "./custom/analytics";
import { HelmetProvider } from "react-helmet-async";

const PreExample = lazy(() => import("./pages/PreExample"));
const PreFetchFilter = lazy(() => import("./pages/PreFetchFilter"));
const PreworkoutProduct = lazy(() => import("./pages/PreworkoutProduct"));

const ProteinExample = lazy(() => import("./pages/ProteinExample"));
const ProteinFetchFilter = lazy(() => import("./pages/ProteinFetchFilter"));
const ProteinPowderProduct = lazy(() => import("./pages/ProteinPowderProduct"));

const ElectrolyteExample = lazy(() => import("./pages/ElectrolyteExample"));
const ElectrolyteFetchFilter = lazy(() =>
  import("./pages/ElectrolyteFetchFilter")
);
const ElectrolyteProduct = lazy(() => import("./pages/ElectrolyteProduct"));

const ProteinBarExample = lazy(() => import("./pages/ProteinBarExample"));
const ProteinBarFetchFilter = lazy(() =>
  import("./pages/ProteinBarFetchFilter")
);
const ProteinBarProduct = lazy(() => import("./pages/ProteinBarProduct"));

const EnergyDrinkExample = lazy(() => import("./pages/EnergyDrinkExample"));
const EnergyFetchFilter = lazy(() => import("./pages/EnergyFetchFilter"));
const EnergyDrinkProduct = lazy(() => import("./pages/EnergyDrinkProduct"));

const OmegaExample = lazy(() => import("./pages/OmegaExample"));
const OmegaFetchFilter = lazy(() => import("./pages/OmegaFetchFilter"));
const OmegaProduct = lazy(() => import("./pages/OmegaProduct"));

const AminoExample = lazy(() => import("./pages/AminoExample"));
const AminoFetchFilter = lazy(() => import("./pages/AminoFetchFilter"));
const AminoProduct = lazy(() => import("./pages/AminoProduct"));

const MassFetchFilter = lazy(() => import("./pages/MassFetchFilter"));
const MassProduct = lazy(() => import("./pages/MassProduct"));

const CreatineExample = lazy(() => import("./pages/CreatineExample"));
const CreatineFetchFilter = lazy(() => import("./pages/CreatineFetchFilter"));
const CreatineProduct = lazy(() => import("./pages/CreatineProduct"));

const BetaExample = lazy(() => import("./pages/BetaExample"));
const BetaFetchFilter = lazy(() => import("./pages/BetaFetchFilter"));
const BetaProduct = lazy(() => import("./pages/BetaProduct"));

const CitrullineExample = lazy(() => import("./pages/CitrullineExample"));
const CitrullineFetchFilter = lazy(() =>
  import("./pages/CitrullineFetchFilter")
);
const CitrullineProduct = lazy(() => import("./pages/CitrullineProduct"));

const About = lazy(() => import("./pages/About"));
const Account = lazy(() => import("./pages/Account"));
const Compare = lazy(() => import("./pages/Compare"));
const ArticlePage = lazy(() => import("./pages/ArticlePage"));
const Article = lazy(() => import("./pages/Article"));

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
  background: white;
`;
const ContentWrap = styled.div`
  padding-bottom: 100px;
`;

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    pageview(location.pathname + location.search);
  }, [location]);
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [apiStatus, setApiStatus] = useState(200);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userfname, setUserfname] = useState("");
  const [userCountry, setUserCountry] = useState(
    process.env.REACT_APP_ENV === "development" ? "US" : null
  ); // defaults to US products
  const [preFilter, setPreFilter] = useState([]);
  const [preFilterLink, setPreFilterLink] = useState(null);
  const [proteinBarFilter, setProteinBarFilter] = useState([]);
  const [proteinBarFilterLink, setProteinBarFilterLink] = useState(null);
  const [proteinFilter, setProteinFilter] = useState([]);
  const [proteinFilterLink, setProteinFilterLink] = useState(null);
  const [electrolyteFilter, setElectrolyteFilter] = useState([]);
  const [electrolyteFilterLink, setElectrolyteFilterLink] = useState(null);
  const [energyDrinkFilter, setEnergyDrinkFilter] = useState([]);
  const [energyDrinkFilterLink, setEnergyDrinkFilterLink] = useState(null);
  const [creatineFilter, setCreatineFilter] = useState([]);
  const [creatineFilterLink, setCreatineFilterLink] = useState(null);
  const [citrullineFilter, setCitrullineFilter] = useState([]);
  const [citrullineFilterLink, setCitrullineFilterLink] = useState(null);
  const [betaFilter, setBetaFilter] = useState([]);
  const [betaFilterLink, setBetaFilterLink] = useState(null);
  const [massFilter, setMassFilter] = useState([]);
  const [massFilterLink, setMassFilterLink] = useState(null);
  const [aminoFilter, setAminoFilter] = useState([]);
  const [aminoFilterLink, setAminoFilterLink] = useState(null);
  const [omegaFilter, setOmegaFilter] = useState([]);
  const [omegaFilterLink, setOmegaFilterLink] = useState(null);
  const [accessories, setAccessories] = useState(null);

  useEffect(() => {
    initializeGA();
    const checkUser = async () => {
      try {
        const response = await fetch(`/api/userCheck`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const userData = await response.json();
        if (response.ok) {
          // console.log(data);
          // user doesn't exist anymore in db
          setLoggedIn(true);
          setVerified(userData.verified);
          setUserEmail(userData.email);
          setUserfname(userData.fname);
          setUserCountry(userData.country);
        } else {
          setUserCountry(userData.country);
          // console.log(userData.country);
          try {
            const response = await fetch("/api/logout", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (response.ok) {
              const data = await response.json();
              // Handle successful response
              // console.log(data)
            } else {
              // Handle server errors
              const errorData = await response.json();

              console.log(errorData.error || "Logout failed");
            }
          } catch (error) {
            console.error("Error during logout:", error);
          }
          setLoggedIn(false);
          setVerified(false);
          setUserEmail("");
          setUserfname("");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during user check:", error);
      }
    };
    const getAccessories = async () => {
      try {
        const response = await fetch(`/api/accessories`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        if (response.ok) {
          // getting accessories
          setAccessories(data);
        }
      } catch (error) {
        console.error("Error getting accessories:", error);
      }
    };
    setLoading(true);
    getAccessories();
    if (process.env.REACT_APP_ENV === "development") {
      setLoggedIn(true);
      setVerified(true);
      setLoading(false);
      // checkUser(); // comment out
    } else {
      checkUser();
    }
  }, []);

  usePageTracking(); // Google Analytics

  // console.log(userCountry);

  return (
    <>
      <HelmetProvider>
        <Fonts />
        <PageContainer>
          <ContentWrap>
            <NavBar loading={loading} isLoggedIn={isLoggedIn} />
            <Suspense fallback={<Loading apiStatus={apiStatus} />}>
              <Routes>
                <Route path="/about" element={<About />} />
                <Route
                  path="/article"
                  element={
                    <ArticlePage
                      userCountry={userCountry}
                      accessories={accessories}
                    />
                  }
                />
                <Route
                  path="/article/:id"
                  element={
                    <Article
                      userCountry={userCountry}
                      setProteinFilter={setProteinFilter}
                      setProteinFilterLink={setProteinFilterLink}
                      setPreFilter={setPreFilter}
                      setPreFilterLink={setPreFilterLink}
                      setProteinBarFilter={setProteinBarFilter}
                      setProteinBarFilterLink={setProteinBarFilterLink}
                      accessories={accessories}
                    />
                  }
                />
                <Route
                  path="/account"
                  element={
                    <Account
                      isLoggedIn={isLoggedIn}
                      isVerified={isVerified}
                      userEmail={userEmail}
                      userfname={userfname}
                      userCountry={userCountry}
                      setUserCountry={setUserCountry}
                      setLoggedIn={setLoggedIn}
                      setVerified={setVerified}
                      setUserEmail={setUserEmail}
                      setUserfname={setUserfname}
                    />
                  }
                />
                <Route
                  path="/"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <Home
                        apiStatus={apiStatus}
                        isLoggedIn={isLoggedIn}
                        isVerified={isVerified}
                        userCountry={userCountry}
                        setPreFilter={setPreFilter}
                        setPreFilterLink={setPreFilterLink}
                        setProteinFilter={setProteinFilter}
                        setProteinFilterLink={setProteinFilterLink}
                        setProteinBarFilter={setProteinBarFilter}
                        setProteinBarFilterLink={setProteinBarFilterLink}
                        setElectrolyteFilter={setElectrolyteFilter}
                        setElectrolyteFilterLink={setElectrolyteFilterLink}
                        setCreatineFilter={setCreatineFilter}
                        setCreatineFilterLink={setCreatineFilterLink}
                        setCitrullineFilter={setCitrullineFilter}
                        setCitrullineFilterLink={setCitrullineFilterLink}
                        setBetaFilter={setBetaFilter}
                        setBetaFilterLink={setBetaFilterLink}
                        setMassFilter={setMassFilter}
                        setMassFilterLink={setMassFilterLink}
                        setAminoFilter={setAminoFilter}
                        setAminoFilterLink={setAminoFilterLink}
                        setEnergyDrinkFilter={setEnergyDrinkFilter}
                        setEnergyDrinkFilterLink={setEnergyDrinkFilterLink}
                        setOmegaFilter={setOmegaFilter}
                        setOmegaFilterLink={setOmegaFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/compare"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <Compare
                        apiStatus={apiStatus}
                        isLoggedIn={isLoggedIn}
                        isVerified={isVerified}
                        userCountry={userCountry}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/aminoacid"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <AminoFetchFilter
                        userCountry={userCountry}
                        setAminoFilter={setAminoFilter}
                        setAminoFilterLink={setAminoFilterLink}
                        accessories={accessories}
                      /> // added productGroup
                    )
                  }
                />
                <Route
                  path="/aminoacid/examples"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <AminoExample
                        userCountry={userCountry}
                        setAminoFilter={setAminoFilter}
                        setAminoFilterLink={setAminoFilterLink}
                        accessories={accessories}
                      /> // added productGroup
                    )
                  }
                />
                <Route
                  path="/aminoacid/:brand/:subtitle/:type/:flavor/:servings/:region"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <AminoProduct
                        aminoFilter={aminoFilter}
                        aminoFilterLink={aminoFilterLink}
                      />
                    )
                  }
                />
                <Route
                  path="/betaalanine"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <BetaFetchFilter
                        userCountry={userCountry}
                        setBetaFilter={setBetaFilter}
                        setBetaFilterLink={setBetaFilterLink}
                        accessories={accessories}
                      /> // added productGroup
                    )
                  }
                />
                <Route
                  path="/betaalanine/examples"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <BetaExample
                        userCountry={userCountry}
                        setBetaFilter={setBetaFilter}
                        setBetaFilterLink={setBetaFilterLink}
                        accessories={accessories}
                      /> // added productGroup
                    )
                  }
                />
                <Route
                  path="/betaalanine/:brand/:type/:flavor/:servings/:region"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <BetaProduct
                        betaFilter={betaFilter}
                        betaFilterLink={betaFilterLink}
                      />
                    )
                  }
                />
                <Route
                  path="/citrulline"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <CitrullineFetchFilter
                        userCountry={userCountry}
                        setCitrullineFilter={setCitrullineFilter}
                        setCitrullineFilterLink={setCitrullineFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/citrulline/examples"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <CitrullineExample
                        userCountry={userCountry}
                        setCitrullineFilter={setCitrullineFilter}
                        setCitrullineFilterLink={setCitrullineFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/citrulline/:brand/:type/:flavor/:servings/:region"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <CitrullineProduct
                        citrullineFilter={citrullineFilter}
                        citrullineFilterLink={citrullineFilterLink}
                      />
                    )
                  }
                />
                <Route
                  path="/creatine"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <CreatineFetchFilter
                        userCountry={userCountry}
                        setCreatineFilter={setCreatineFilter}
                        setCreatineFilterLink={setCreatineFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/creatine/examples"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <CreatineExample
                        userCountry={userCountry}
                        setCreatineFilter={setCreatineFilter}
                        setCreatineFilterLink={setCreatineFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/creatine/:brand/:type/:flavor/:servings/:region/:patent"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <CreatineProduct
                        creatineFilter={creatineFilter}
                        creatineFilterLink={creatineFilterLink}
                      />
                    )
                  }
                />
                <Route
                  path="/electrolyte"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <ElectrolyteFetchFilter
                        userCountry={userCountry}
                        setElectrolyteFilter={setElectrolyteFilter}
                        setElectrolyteFilterLink={setElectrolyteFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/electrolyte/examples"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <ElectrolyteExample
                        userCountry={userCountry}
                        setElectrolyteFilter={setElectrolyteFilter}
                        setElectrolyteFilterLink={setElectrolyteFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/electrolyte/:brand/:type/:flavor/:servings/:region"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <ElectrolyteProduct
                        electrolyteFilter={electrolyteFilter}
                        electrolyteFilterLink={electrolyteFilterLink}
                      />
                    )
                  }
                />
                <Route
                  path="/energydrink/examples"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <EnergyDrinkExample
                        userCountry={userCountry}
                        setEnergyDrinkFilter={setEnergyDrinkFilter}
                        setEnergyDrinkFilterLink={setEnergyDrinkFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/energydrink"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <EnergyFetchFilter
                        userCountry={userCountry}
                        setEnergyDrinkFilter={setEnergyDrinkFilter}
                        setEnergyDrinkFilterLink={setEnergyDrinkFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/energydrink/:brand/:subtitle/:flavor/:servings/:region"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <EnergyDrinkProduct
                        energyDrinkFilter={energyDrinkFilter}
                        energyDrinkFilterLink={energyDrinkFilterLink}
                      />
                    )
                  }
                />
                <Route
                  path="/massgainer"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <MassFetchFilter
                        userCountry={userCountry}
                        setMassFilter={setMassFilter}
                        setMassFilterLink={setMassFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/massgainer/:brand/:subtitle/:flavor/:servings/:region"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <MassProduct
                        massFilter={massFilter}
                        massFilterLink={massFilterLink}
                      />
                    )
                  }
                />
                <Route
                  path="/omega3"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <OmegaFetchFilter
                        userCountry={userCountry}
                        setOmegaFilter={setOmegaFilter}
                        setOmegaFilterLink={setOmegaFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/omega3/examples"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <OmegaExample
                        userCountry={userCountry}
                        setOmegaFilter={setOmegaFilter}
                        setOmegaFilterLink={setOmegaFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/omega3/:brand/:subtitle/:type/:flavor/:servings/:region"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <OmegaProduct
                        omegaFilter={omegaFilter}
                        omegaFilterLink={omegaFilterLink}
                      />
                    )
                  }
                />
                <Route
                  path="/proteinbar"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <ProteinBarFetchFilter
                        userCountry={userCountry}
                        setProteinBarFilter={setProteinBarFilter}
                        setProteinBarFilterLink={setProteinBarFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/proteinbar/examples"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <ProteinBarExample
                        userCountry={userCountry}
                        setProteinBarFilter={setProteinBarFilter}
                        setProteinBarFilterLink={setProteinBarFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/proteinbar/:brand/:subtitle/:flavor/:servings/:region"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <ProteinBarProduct
                        proteinBarFilter={proteinBarFilter}
                        proteinBarFilterLink={proteinBarFilterLink}
                      />
                    )
                  }
                />
                <Route
                  path="/proteinpowder"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <ProteinFetchFilter
                        userCountry={userCountry}
                        setProteinFilter={setProteinFilter}
                        setProteinFilterLink={setProteinFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/proteinpowder/examples"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <ProteinExample
                        userCountry={userCountry}
                        setProteinFilter={setProteinFilter}
                        setProteinFilterLink={setProteinFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/proteinpowder/:brand/:type/:flavor/:size/:region"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <ProteinPowderProduct
                        proteinFilter={proteinFilter}
                        proteinFilterLink={proteinFilterLink}
                      />
                    )
                  }
                />
                <Route
                  path="/preworkout"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <PreFetchFilter
                        userCountry={userCountry}
                        setPreFilter={setPreFilter}
                        setPreFilterLink={setPreFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/preworkout/examples"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <PreExample
                        userCountry={userCountry}
                        setPreFilter={setPreFilter}
                        setPreFilterLink={setPreFilterLink}
                        accessories={accessories}
                      />
                    )
                  }
                />
                <Route
                  path="/preworkout/:brand/:subtitle/:flavor/:servings/:region"
                  element={
                    apiStatus !== 200 ? (
                      <Loading apiStatus={apiStatus} />
                    ) : (
                      <PreworkoutProduct
                        preFilter={preFilter}
                        preFilterLink={preFilterLink}
                      />
                    )
                  }
                />
                <Route path="*" element={<Error />} />
                {/* <Route path="/loading" element={<Loading />} /> */}
              </Routes>
            </Suspense>
          </ContentWrap>
          <Footer />
        </PageContainer>
      </HelmetProvider>
    </>
  );
};

export default App;
