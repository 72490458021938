import React from "react";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const LoadingDiv = styled.div`
  padding-top: 50px;
`;
const MaintenanceDiv = styled.div`
  padding: 80px 0px;
`;

const MaintenanceTitle = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  position: static;
  text-align: center;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
  }
`;

const MaintenanceSubTitle = styled.p`
  font-size: 1rem;
  font-family: San Francisco Regular;
  color: gray;
  text-align: center;
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 20px;
  white-space: pre-line;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;

const Loading = (props) => {
  return (
    <center>
      {props.apiStatus === 200 && (
        <LoadingDiv>
          {/* <h1
            style={{
              fontFamily: "San Francisco Bold",
              fontSize: "1.5rem",
              paddingBottom: "10px",
            }}
          >
            Updating...
          </h1> */}
          <CircularProgress color="inherit" />
        </LoadingDiv>
      )}
      {props.apiStatus === 404 && (
        <MaintenanceDiv>
          <MaintenanceTitle>SUPPHEAD is offline.</MaintenanceTitle>
          <MaintenanceSubTitle>Please check back later!</MaintenanceSubTitle>
        </MaintenanceDiv>
      )}
      {props.apiStatus === 429 && (
        <MaintenanceDiv>
          <MaintenanceTitle>Too Many Requests</MaintenanceTitle>
          <MaintenanceSubTitle>Please slow down!</MaintenanceSubTitle>
        </MaintenanceDiv>
      )}
    </center>
  );
};

export default Loading;
