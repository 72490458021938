import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GradientAnimation } from "../custom/animations";
import { DollarSVG, TickerDownSVG, TickerUpSVG } from "../custom/svg";
import { ChartSVG } from "../custom/svg";
import { CircularProgress } from "@mui/material";

const ListRoundedSquare = styled(Link).attrs((props) => ({
  color: props.color,
}))`
  display: block;
  text-decoration: none;
  color: black;
  width: 95%;
  height: 100px;
  border-radius: 15px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background: ${(props) =>
    props.rank <= 3
      ? "linear-gradient(135deg, " +
        props.color +
        " 0%, " +
        props.color +
        " 45%, white 50%, " +
        props.color +
        " 55%, " +
        props.color +
        " 100%)"
      : "#efefef"};
  background-size: 400% 400%;
  animation: ${GradientAnimation} 10s ease infinite;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 10px gray;
    }
  }
`;
const FrontRoundedSquare = styled.div`
  height: 90px;
  background-color: white;
  border-radius: 10px;
  position: static;
  margin: 5px;
`;
const ListOuterDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  width: 100%;
`;
const ListContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: 10px;
`;
const ListImageDiv = styled.div`
  height: 80%;
  aspect-ratio: 1 / 1;
  margin-left: 5px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
`;
const ListImage = styled.img`
  height: 100%;
`;
const ListRank = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.2rem;
  position: static;
  margin-right: auto;
  margin-left: 0;

  @media (max-width: 760px) {
    font-size: 1rem;
  }
`;
const MobileTitleDiv = styled.div`
  //margin-top: 5px;
  height: auto;
  min-width: 80%;
  // margin-top: 10px;
  // margin-top: 10px;
`;
const PodiumBrand = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.2rem;
  text-align: left;
  margin: 0px 0px;
  line-height: 1.45rem;

  @media (max-width: 760px) {
    font-size: 1rem;
    line-height: 1.3rem;
  }
`;
const PodiumFlavor = styled.p`
  font-family: San Francisco Regular;
  font-size: 0.9rem;
  text-align: left;
  color: gray;
  padding-left: 10px;
  margin-top: 0px;
  margin-bottom: 2px;
`;

const MobileInfoDiv = styled.div`
  display: flex;
  font-family: San Francisco Regular;
  flex-direction: row;
  align-items; center;
  width: 100%;
  padding-left: 5px;

  @media (min-width: 760px) {
    display: none;
  }
`;
const DesktopInfoDiv = styled.div`
  display: flex;
  font-family: San Francisco Regular;
  flex-direction: row;
  align-items; center;
  width: 100%;
  padding-left: 0px;

  // @media (min-width: 760px) {
  //   display: none;
  // }
`;
const ListItemBar = styled.div`
  display: block;
  width: 2px;
  border-radius: 50px;
  background-color: ${(props) => props.color};
  margin-right: 5px;
  height: 72px;
`;
const ListStatsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 150px;
  height: 70px;

  @media (max-width: 760px) {
    display: none;
  }
`;
const ListStats = styled.div`
  font-family: San Francisco Regular;
  font-size: 0.9rem;
  color: gray;
`;

const DesktopListInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LoadingDiv = styled.div`
  display: flex;
  width: 80%;
  height: 100%;
  align-items: center;
`;
const BrandTickerDiv = styled.div`
  display: flex;
  padding-left: 10px;
  margin-bottom: 5px;
  // gap: 10px;
  justify-content: left;
  align-items: center;
`;
const TickerDigit = styled.p`
  margin: 0px;
  margin-left: 5px;
  font-size: 12px;
  font-family: San Francisco Bold;
`;
const TickerUpDiv = styled.div`
  display: flex;
  justify-content: center;
  transform: translateY(-1px);
`;
const TickerDownDiv = styled.div`
  display: flex;
  justify-content: center;
  transform: translateY(1px);
`;

const ElectrolyteTypes = [
  {
    value: "powder",
    label: "Powder",
  },
  {
    value: "capsule",
    label: "Capsules",
  },
  {
    value: "packet",
    label: "Packets",
  },
  {
    value: "tablet",
    label: "Tablets",
  },
];

const LargePhotoBrandList = [
  "Bulk Protein Co.",
  "PEScience",
  "Limitless",
  "Klean",
  "Podium",
  "Key Nutrients",
  "Santa Cruz Paleo",
];

const BrandListCompact = (props) => {
  let color = "";
  let sign = "$";

  if (props.userCountry === "GB") {
    sign = "£";
  }

  if (props.filterRank === 1) {
    color = "#d4af37";
  } else if (props.filterRank === 2) {
    color = "#C0C0C0";
  } else if (props.filterRank === 3) {
    color = "#CD7F32";
  } else {
    color = "#efefef";
  }

  let link = "";
  let creatinePatent = "";

  if (!props.loading) {
    if (props.item.category === "proteinpowder") {
      link =
        "/" +
        props.item.category +
        "/" +
        props.item.brand +
        "/" +
        props.item.type +
        "/" +
        props.item.flavor +
        "/" +
        props.item.size +
        "/" +
        props.item.region;
    } else if (
      props.item.category === "preworkout" ||
      props.item.category === "massgainer" ||
      props.item.category === "proteinbar" ||
      props.item.category === "energydrink"
    ) {
      link =
        "/" +
        props.item.category +
        "/" +
        props.item.brand +
        "/" +
        props.item.subtitle +
        "/" +
        props.item.flavor +
        "/" +
        props.item.servings +
        "/" +
        props.item.region;
    } else if (props.item.category === "electrolyte") {
      link =
        "/" +
        props.item.category +
        "/" +
        props.item.brand +
        "/" +
        props.item.type +
        "/" +
        props.item.flavor +
        "/" +
        props.item.servings +
        "/" +
        props.item.region;
    } else if (
      props.item.category === "aminoacid" ||
      props.item.category === "omega3"
    ) {
      link =
        "/" +
        props.item.category +
        "/" +
        props.item.brand +
        "/" +
        props.item.subtitle +
        "/" +
        props.item.type +
        "/" +
        props.item.flavor +
        "/" +
        props.item.servings +
        "/" +
        props.item.region;
    } else if (
      props.item.category === "betaalanine" ||
      props.item.category === "citrulline"
    ) {
      link =
        "/" +
        props.item.category +
        "/" +
        props.item.brand +
        "/" +
        props.item.type +
        "/" +
        props.item.flavor +
        "/" +
        props.item.servings +
        "/" +
        props.item.region;
    } else if (props.item.category === "creatine") {
      link =
        "/" +
        props.item.category +
        "/" +
        props.item.brand +
        "/" +
        props.item.type +
        "/" +
        props.item.flavor +
        "/" +
        props.item.servings +
        "/" +
        props.item.region +
        "/" +
        props.item.patent;
    }

    if (props.item.category === "creatine") {
      if (props.item.creapure !== null) {
        creatinePatent = " (Creapure®)";
      } else if (props.item.pharmapure !== null) {
        creatinePatent = " (PharmaPure™)";
      } else if (props.item.concret !== null) {
        creatinePatent = " (CON-CRET®)";
      } else if (props.item.creasyn !== null) {
        creatinePatent = " (CreaSyn™)";
      } else if (props.item.chcl !== null) {
        creatinePatent = " (C-HCl®)";
      } else {
        if (props.item.type === "Micronized Monohydrate") {
          creatinePatent = " Monohydrate";
        }
      }
    }
  }

  return (
    <ListRoundedSquare
      to={link}
      color={color}
      rank={props.filterRank}
      onClick={() => {
        if (props.filter && props.filterLink) {
          props.setFilter(props.filter);
          props.setFilterLink(props.filterLink);
          // set product group to trigger app level useEffect to fetch the correct product info
        }
      }}
    >
      <FrontRoundedSquare>
        {!props.loading ? (
          <ListOuterDiv>
            <ListContentDiv>
              <ListImageDiv
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ListImage src={props.item.imglink} style={{ height: "80%" }} />
              </ListImageDiv>
              <ListRank>{props.filterRank}.</ListRank>
              <MobileTitleDiv>
                {props.oldRank - props.filterRank !== 0 && props.ticker ? (
                  <BrandTickerDiv>
                    <PodiumBrand style={{ marginRight: "8px" }}>
                      {props.item.brand}
                    </PodiumBrand>
                    {props.oldRank - props.filterRank > 0 && (
                      <TickerUpDiv>
                        <TickerUpSVG />
                      </TickerUpDiv>
                    )}
                    {props.oldRank - props.filterRank < 0 && (
                      <TickerDownDiv>
                        <TickerDownSVG />
                      </TickerDownDiv>
                    )}
                    <TickerDigit>
                      {props.oldRank - props.filterRank}
                    </TickerDigit>
                    {/* design <TickerDigit> to be the difference of last times rank (indexOf(prod) - indexOf(sort shallow copy of data by old_value_rank) should be a prop */}
                    {/* <TickerDownSVG /> */}
                  </BrandTickerDiv>
                ) : (
                  <BrandTickerDiv>
                    <PodiumBrand>{props.item.brand}</PodiumBrand>
                  </BrandTickerDiv>
                )}
                {props.item.category === "proteinpowder" && (
                  <>
                    <PodiumFlavor>
                      {props.item.type === "Clear" && "Clear Whey Isolate"}
                      {props.item.type === "Vegan" && "Vegan"}
                      {props.item.type !== "Clear" &&
                        props.item.type !== "Vegan" &&
                        "Whey " + props.item.type}
                    </PodiumFlavor>
                    <MobileInfoDiv>
                      <ChartSVG />
                      <p
                        style={{
                          margin: "6px 0px 0px 3px",
                          color: "gray",
                          fontSize: "11px",
                        }}
                      >
                        {props.item.min_servings !== props.item.max_servings
                          ? props.item.min_servings +
                            "-" +
                            props.item.max_servings +
                            " Servings"
                          : props.item.servings + " Servings"}{" "}
                        {props.ai &&
                          (props.item.min_price !== props.item.max_price
                            ? "‧ " +
                              sign +
                              props.item.min_price +
                              "-" +
                              sign +
                              props.item.max_price
                            : "‧ " + sign + props.item.min_price)}
                      </p>
                    </MobileInfoDiv>
                  </>
                )}
                {(props.item.category === "preworkout" ||
                  props.item.category === "massgainer" ||
                  props.item.category === "proteinbar" ||
                  props.item.category === "aminoacid" ||
                  props.item.category === "energydrink" ||
                  props.item.category === "omega3") && (
                  <>
                    <PodiumFlavor>{props.item.subtitle}</PodiumFlavor>
                    <MobileInfoDiv>
                      <ChartSVG />
                      <p
                        style={{
                          margin: "6px 0px 0px 3px",
                          color: "gray",
                          fontSize: "11px",
                        }}
                      >
                        {props.item.min_servings !== props.item.max_servings
                          ? props.item.min_servings +
                            "-" +
                            props.item.max_servings +
                            " Servings"
                          : props.item.twoscoop
                          ? parseFloat(props.item.servings) / 2 +
                            "/" +
                            props.item.servings +
                            " Servings"
                          : props.item.servings + " Servings"}{" "}
                        {props.ai &&
                          (props.item.min_price !== props.item.max_price
                            ? "‧ " +
                              sign +
                              props.item.min_price +
                              "-" +
                              sign +
                              props.item.max_price
                            : "‧ " + sign + props.item.min_price)}
                      </p>
                    </MobileInfoDiv>
                  </>
                )}
                {props.item.category === "electrolyte" && (
                  <>
                    <PodiumFlavor>
                      Electrolyte{" "}
                      {
                        ElectrolyteTypes[
                          ElectrolyteTypes.findIndex(
                            (i) => i.value === props.item.type
                          )
                        ].label
                      }
                    </PodiumFlavor>
                    <MobileInfoDiv>
                      <ChartSVG />
                      <p
                        style={{
                          margin: "6px 0px 0px 3px",
                          color: "gray",
                          fontSize: "11px",
                        }}
                      >
                        {props.item.min_servings !== props.item.max_servings
                          ? props.item.min_servings +
                            "-" +
                            props.item.max_servings +
                            " Servings"
                          : props.item.servings + " Servings"}{" "}
                        {props.ai &&
                          (props.item.min_price !== props.item.max_price
                            ? "‧ " +
                              sign +
                              props.item.min_price +
                              "-" +
                              sign +
                              props.item.max_price
                            : "‧ " + sign + props.item.min_price)}
                      </p>
                    </MobileInfoDiv>
                  </>
                )}
                {props.item.category === "creatine" && (
                  <>
                    <PodiumFlavor>
                      {props.item.type === "Micronized Monohydrate"
                        ? "Micronized" + creatinePatent
                        : props.item.type + creatinePatent}
                    </PodiumFlavor>
                    <MobileInfoDiv>
                      <ChartSVG />
                      <p
                        style={{
                          margin: "6px 0px 0px 3px",
                          color: "gray",
                          fontSize: "11px",
                        }}
                      >
                        {props.item.min_servings !== props.item.max_servings
                          ? props.item.min_servings +
                            "-" +
                            props.item.max_servings +
                            " Servings"
                          : props.item.servings + " Servings"}
                        {props.ai &&
                          (props.item.min_price !== props.item.max_price
                            ? "‧ " +
                              sign +
                              props.item.min_price +
                              "-" +
                              sign +
                              props.item.max_price
                            : "‧ " + sign + props.item.price)}
                      </p>
                    </MobileInfoDiv>
                  </>
                )}
                {(props.item.category === "betaalanine" ||
                  props.item.category === "citrulline") && (
                  <>
                    <PodiumFlavor>
                      {props.item.type === "powder"
                        ? props.item.flavor + " Powder"
                        : "Capsules"}
                    </PodiumFlavor>
                    <MobileInfoDiv>
                      <ChartSVG />
                      <p
                        style={{
                          margin: "6px 0px 0px 3px",
                          color: "gray",
                          fontSize: "11px",
                        }}
                      >
                        {props.item.min_servings !== props.item.max_servings
                          ? props.item.min_servings +
                            "-" +
                            props.item.max_servings +
                            " Servings"
                          : props.item.servings + " Servings"}
                        {props.ai &&
                          (props.item.min_price !== props.item.max_price
                            ? "‧ " +
                              sign +
                              props.item.min_price +
                              "-" +
                              sign +
                              props.item.max_price
                            : "‧ " + sign + props.item.price)}
                      </p>
                    </MobileInfoDiv>
                  </>
                )}
              </MobileTitleDiv>
            </ListContentDiv>
            <DesktopListInfo>
              <ListStatsDiv>
                <ListStats>
                  <DesktopInfoDiv>
                    <DollarSVG />
                    <p
                      style={{
                        margin: "5px 0px 0px 3px",
                        color: "gray",
                        fontSize: "12px",
                      }}
                    >
                      {props.ai &&
                        (props.item.category === "proteinpowder" ||
                          props.item.category === "preworkout" ||
                          props.item.category === "electrolyte" ||
                          props.item.category === "massgainer" ||
                          props.item.category === "proteinbar" ||
                          props.item.category === "aminoacid" ||
                          props.item.category === "energydrink" ||
                          props.item.category === "omega3") &&
                        (props.item.min_price !== props.item.max_price
                          ? sign +
                            props.item.min_price +
                            "-" +
                            sign +
                            props.item.max_price
                          : sign + props.item.min_price)}

                      {props.ai &&
                        (props.item.category === "creatine" ||
                          props.item.category === "betaalanine" ||
                          props.item.category === "citrulline") &&
                        (props.item.min_price !== props.item.max_price
                          ? sign +
                            props.item.min_price +
                            "-" +
                            sign +
                            props.item.max_price
                          : sign + props.item.price)}
                    </p>
                  </DesktopInfoDiv>
                </ListStats>
                <ListStats>
                  <DesktopInfoDiv>
                    <ChartSVG />
                    <p
                      style={{
                        margin: "5px 0px 0px 3px",
                        color: "gray",
                        fontSize: "12px",
                      }}
                    >
                      {props.ai &&
                        (props.item.category === "proteinpowder" ||
                          props.item.category === "preworkout" ||
                          props.item.category === "electrolyte" ||
                          props.item.category === "massgainer" ||
                          props.item.category === "proteinbar" ||
                          props.item.category === "aminoacid" ||
                          props.item.category === "energydrink" ||
                          props.item.category === "omega3") &&
                        (props.item.min_servings !== props.item.max_servings
                          ? props.item.min_servings +
                            "-" +
                            props.item.max_servings +
                            " Servings"
                          : props.item.twoscoop
                          ? parseFloat(props.item.servings) / 2 +
                            "/" +
                            props.item.servings +
                            " Servings"
                          : props.item.servings + " Servings")}

                      {props.ai &&
                        props.item.category === "creatine" &&
                        (props.item.min_servings !== props.item.max_servings
                          ? props.item.min_servings +
                            "-" +
                            props.item.max_servings +
                            " Servings"
                          : props.item.servings + " Servings")}

                      {props.ai &&
                        (props.item.category === "betaalanine" ||
                          props.item.category === "citrulline") &&
                        (props.item.min_servings !== props.item.max_servings
                          ? props.item.min_servings +
                            "-" +
                            props.item.max_servings +
                            " Servings"
                          : props.item.servings + " Servings")}
                    </p>
                  </DesktopInfoDiv>
                </ListStats>
              </ListStatsDiv>
              <ListItemBar color={color} />
            </DesktopListInfo>
          </ListOuterDiv>
        ) : (
          <LoadingDiv>
            <CircularProgress color="inherit" />{" "}
          </LoadingDiv>
        )}
      </FrontRoundedSquare>
    </ListRoundedSquare>
  );
};

export default BrandListCompact;
